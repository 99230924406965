
import { defineComponent, ref } from "vue";
import UploadSchedule from "@/views/cooperative-admin/insurance-packages/motor-insurance/UploadSchedule.vue";
import MotorInsuranceForm from "@/components/reusable/LegacyMotorInsuranceForm.vue";
import { Form, Field } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useStore } from "vuex";

export default defineComponent({
  name: "motor-insurance-migration-component",
  components: {
    Field,
    Form,
    MotorInsuranceForm,
    UploadSchedule,
  },
  data() {
    return {
      displaySchedule: false,
      displayForm: false,
      userRole: "",
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const store = useStore();
    const validationSchema = Yup.object().shape({
      vehicles: Yup.array()
        .of(
          Yup.object().shape({
            owner: Yup.string()
              .required()
              // .min(6)
              .label("Vehicle owner name"),
            vehicleValue: Yup.string()
              .when("package", {
                is: (value) => value != JwtService.getThirdPartyPM(),
                then: Yup.string()
                  // .min(6)
                  .required(),
              })
              .label("Vehicle value"),
            package: Yup.mixed()
              .required()
              .label("Cover"),
            vehicleMake: Yup.mixed()
              .required()
              .label("Vehicle brand"),
            vehicleModel: Yup.string()
              .required()
              .label("Vehicle model"),
            registrationNumber: Yup.string()
              .required()
              // .min(8)
              .label("Vehicle registration number"),
            chassisNumber: Yup.string()
              .required()
              .min(1)
              .label("Chassis number"),
            engineNumber: Yup.string()
              .required()
              .min(1)
              .label("Engine number"),
            bodyType: Yup.string()
              .required()
              .label("Body type"),
            vehicleColor: Yup.string()
              .required()
              .label("Vehicle colour"),
            vehicleLicense: Yup.mixed()
              .required()
              .label("Vehicle license"),
          })
        )
        .strict(),
    });

    const createLegacyMotorinsurance = (values, { resetForm }) => {
      console.clear();

      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (let x = 0; x < values["vehicles"].length; x++) {
              for (const [key, value] of Object.entries(
                values["vehicles"][x]
              )) {
                if (Array.isArray(value)) {
                  value.forEach((itemValue, index) => {
                    formData.append(
                      `vehicleDetails[${key}][${x}][${index}]`,
                      itemValue as string | Blob
                    );
                  });
                } else {
                  formData.append(
                    `vehicleDetails[${key}][${x}]`,
                    value as string | Blob
                  );
                }
              }
            }

            formData.append("clientPublicId", values["clientPublicId"]);
            formData.append(
              "underwriterPublicId",
              values["underwriterPublicId"]
            );
            formData.append("paymentReceipt", values["paymentReceipt"]);
            formData.append("policyDocument", values["policyDocument"]);
            formData.append("policyStartDate", values["policyStartDate"]);
            formData.append("policyEndDate", values["policyEndDate"]);
            formData.append("totalSumInsured", values["totalSumInsured"]);
            formData.append("policyNumber", values["policyNumber"]);
            formData.append("businessType", values["businessType"]);
            formData.append("businessClass", "MOT");
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(variables.LEGACY_POLICY, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                console.clear();
                console.log(response);
                resetForm();
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: "admin-policy-migration-list",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return {
      submitButton1,
      createLegacyMotorinsurance,
      validationSchema,
    };
  },
  methods: {
    uploadSchedule() {
      this.displaySchedule = true;
      this.displayForm = false;
    },
    motorForm() {
      this.displayForm = true;
      this.displaySchedule = false;
    },
  },
});
