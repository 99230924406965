
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import VehicleMakeAndModel from "@/components/reusable/VehicleMakeAndModel.vue";
import VehicleRegAndChassisNumber from "@/components/reusable/VehicleRegAndChassisNumber.vue";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import VehicleColorChart from "@/components/modals/VehicleColorChart.vue";
import VehicleBodyTypesModal from "@/components/modals/VehicleBodyTypes.vue";
import VehicleColors from "@/views/reusable/buy-insurance/motor-insurance/vehicle-colours.json";
import VehicleBodyTypes from "@/views/reusable/buy-insurance/motor-insurance/vehicle-body-types.json";
import Insurer from "@/components/reusable/Insurer.vue";
import VehicleUsage from "@/components/reusable/VehicleUsage.vue";

export default defineComponent({
  name: "cooperative-motor-comprehensive-component",
  components: {
    Field,
    ErrorMessage,
    VehicleMakeAndModel,
    VehicleRegAndChassisNumber,
    ImagesUploader,
    VehicleColorChart,
    VehicleBodyTypesModal,
    Insurer,
    VehicleUsage,
  },
  data() {
    return {
      clientPublicId: "",
      policyNumber: "",
      businessType: "",
      policyStartDate: "",
      policyEndDate: "",
      underwriterPublicId: "",
      paymentReceipt: "",
      policyDocument: "",
      brokerReference: "",
      vehicles: [
        {
          vehicleOwner: "",
          vehicleValue: "",
          vehicleMake: "",
          vehicleModel: "",
          vehicleRegistrationNumber: "",
          chassisNumber: "",
          engineNumber: "",
          engineCapacity: "",
          yearManufactured: "",
          vehicleColor: "",
          bodyType: "",
          vehicleLicense: "",
          vehicleImages: "",
          vehiclePremium: "",
          rate: 0.0 as number,
        },
      ],
      totalPremium: 0.0 as number,
      totalSumInsured: "",
      vehicleColors: VehicleColors,
      vehicleBodyTypes: VehicleBodyTypes,
    };
  },
  setup() {
    const covers = ref([]);
    const underwriters = ref([]);
    const clients = ref([
      { publicId: "", fullName: "", firstName: "", lastName: "" },
    ]);

    const getUnderwriters = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_UNDERWRITERS_LIST_ROUTE)
          .then(({ data }) => {
            underwriters.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getLegacyClients = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.LEGACY_CLIENT)
          .then(({ data }) => {
            clients.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getCovers = (businessType: string) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.INSURANCE_PACKAGES_ROUTE}/${businessType}`)
          .then(({ data }) => {
            covers.value = data.data;
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    // const numberFormatter = () => {
    //   // Add comma and allow decimal point vehicle value field
    //   const ids = ["total-sum-insured"];
    //   ids.forEach((id: string) => {
    //     variables.numberFormatter(id);
    //   });
    // };

    onMounted(() => {
      getUnderwriters();
      getLegacyClients();
    });

    return { covers, getCovers, underwriters, clients };
  },
  methods: {
    premium(event) {
      // Add comma and allow decimal point vehicle value field
      variables.numberFormatter(event.target.id);

      // Remove comma from vehicle value
      const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        this.totalPremiumCalculator();
      } else {
        return;
      }
    },
    addDynamicField() {
      this.vehicles.push({
        vehicleOwner: "",
        vehicleValue: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleRegistrationNumber: "",
        chassisNumber: "",
        engineNumber: "",
        engineCapacity: "",
        yearManufactured: "",
        vehicleColor: "",
        bodyType: "",
        vehicleLicense: "",
        vehicleImages: "",
        vehiclePremium: "",
        rate: 0.0 as number,
      });

      variables.toastAlert("Row added", "success");
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      this.vehicles.splice(counter, 1);
      variables.toastAlert("Row removed", "success");

      setTimeout(() => {
        // Update total premium
        this.totalPremiumCalculator();
      }, 200);
    },
    vehicleLicenseSelected(event, position) {
      this.vehicles[position].vehicleLicense = event.target.files[0];
    },
    vehicleImagesSelected(event, position) {
      this.vehicles[position].vehicleImages = event.target.files;
    },
    vehicleOwnerNameUpdate(event, position) {
      const realVehicleOwnerName = document.getElementById(
        "real-vehicle-owner-" + position
      ) as HTMLInputElement;

      const value = event.target.value;
      realVehicleOwnerName.value = value;
      this.vehicles[position].vehicleOwner = value;
    },
    getUnderwriterId(underwriterId) {
      this.underwriterPublicId = underwriterId;
    },
    policyImageSelected(event, name) {
      this[name] = event.target.files[0];
    },
    totalPremiumCalculator() {
      const grandPremium = document.getElementById(
        "total-premium"
      ) as HTMLInputElement;

      // Calculate premium from vehicle value
      let total = 0;
      this.vehicles.forEach((vehicle) => {
        total += variables.removeComma(vehicle.vehiclePremium);
      });

      console.log(total);

      // Append total premium to widget
      grandPremium.textContent = total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // Append total premium value tot total premium field
      return (this.totalPremium = total);
    },
    numberFormatter(event) {
      variables.numberFormatter(event.target.id);
    },
  },
});
